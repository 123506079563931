<template>
  <div>
    <pre>{{ shipment }}</pre>
    <div v-if="false" class="bg-indigo-500 w-full md:w-1/2">
      <div class="rounded-md text-white pt-2 px-6 mt-2 pointer">
        <router-link :to="{ path: '/reparto/' }" class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
        </router-link>
      </div>
      <div
        class="m-4 my-4 border-dashed border-2 border-sky-500 grid grid-cols-1 divide-y-2"
        v-if="$route.params.id"
      >
        <div class="p-4 bg-gray-200">
          <pre>{{ orsai }}</pre>
          <p class="text-sm font-bold uppercase text-gray-500 my-1">Nombre</p>
          <p class="font-bold">{{ orsai.nombre }} ({{ orsai.telefono }})</p>
        </div>
        <div class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">ORDEN</p>
          <p class="font-bold">{{ $route.params.id }}</p>
        </div>
        <div class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">
            Direccion
          </p>
          <p class="font-bold">{{ orsai.direccion }}, {{ orsai.pais }}</p>
        </div>
        <div class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">ESTADO</p>
          <div class="my-4">
            <p
              v-if="orsai.estado == 'Entregado'"
              class="p-2 bg-green-500 text-white uppercase font-bold"
            >
              {{ orsai.estado }}
            </p>
            <p
              v-if="orsai.estado == 'En Camino'"
              class="p-2 bg-orange-500 text-white uppercase font-bold"
            >
              {{ orsai.estado }}
            </p>
            <p
              v-if="orsai.estado == 'Pendiente'"
              class="p-2 bg-orange-500 text-white uppercase font-bold"
            >
              {{ orsai.estado }}
            </p>
            <p
              v-if="orsai.estado == 'Demorado'"
              class="p-2 bg-red-500 text-white uppercase font-bold"
            >
              <span class=""></span> {{ orsai.estado }}
            </p>
          </div>
        </div>
        <div v-if="orsai.cliente.dni" class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">
            DNI de la persona que lo recibió
          </p>
          <p class="font-bold">{{ orsai.cliente.dni }}</p>
        </div>
        <div class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">
            PRODUCTOS
          </p>
          <p class="font-bold">{{ orsai.producto }}</p>
        </div>
        <div class="p-4 bg-white">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">
            REMITENTE
          </p>
          <p class="font-bold">{{ orsai.remitente }}</p>
        </div>
        <div class="p-4 bg-white" v-if="motoqueroMensajes.length > 0">
          <p class="text-sm font-bold uppercase text-gray-500 my-1">Mensajes</p>
          <!-- <h3 class="pt-4 text-3xl my-8 font-bold">Mensajes</h3> -->
          <div
            class="flex flex-col my-4"
            v-for="item in motoqueroMensajes"
            :key="item.id"
          >
            <div class="w-100">
              <p class="text-center font-bold">{{ item.fecha }}</p>
            </div>
            <div class="w-100">
              <p class="text-center">{{ item.nota }}</p>
            </div>
          </div>
        </div>
        <div class="p-4 bg-white">
          <div
            class="py-2"
            v-if="
              orsai.estado == 'En Camino' ||
              orsai.estado == 'Entregado' ||
              orsai.estado == 'Demorado'
            "
          >
            <div
              v-if="
                producto.estado == 'En Camino' ||
                (producto.estado == 'Demorado' && !mensaje)
              "
            >
              <div class="mb-6 my-2" v-if="entregar">
                <h3 class="pt-4 text-3xl mb-4 font-bold">DNI</h3>
                <input
                  placeholder="39.325.932"
                  type="text"
                  id="large-input"
                  class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-2xl focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  v-model="cliente.dni"
                />
                <button
                  @click="enviarEstado()"
                  v-if="
                    producto.estado == 'En Camino' ||
                    (producto.estado == 'Demorado' && cliente.dni)
                  "
                  class="w-full bg-transparent bg-black font-semibold text-white py-3 px-4 mt-4 border border-black-500 hover:border-transparent rounded"
                >
                  ENTREGAR
                </button>
              </div>
            </div>
            <div
              class="input-group"
              v-if="producto.estado == 'Demorado' && !cliente.dni"
            >
              <h3 class="pt-4 text-3xl mb-4 font-bold">Paso Algo ?</h3>

              <textarea
                id="message"
                rows="4"
                v-model="mensaje"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Escribe lo que paso..."
              ></textarea>
              <button
                @click="enviarEstadoDemorado()"
                class="btn bg-base-700 text-white hover:bg-base-400 hover:text-blac w-full mt-8"
              >
                ENVIAR ESTADO
              </button>
            </div>
            <button
              @click="entregar = true"
              v-if="
                !entregar &&
                producto.estado != 'Entregado' &&
                producto.estado != 'Demorado'
              "
              class="w-full bg-transparent bg-black font-semibold text-white py-3 px-4 mt-4 border border-black-500 hover:border-transparent rounded"
            >
              ENTREGAR
            </button>
            <button
              @click="noEntregado()"
              v-if="producto.estado == 'En Camino' && !entregar"
              class="w-full bg-transparent bg-yellow-500 font-semibold hover:text-white py-3 px-4 mt-4 border border-black-500 hover:border-transparent rounded"
            >
              DEMORADO
            </button>
          </div>
          <div class="grid grid-cols-2">
            <div class="pr-2">
              <button
                class="w-full bg-transparent bg-yellow-500 font-semibold hover:text-white py-2 px-4 mt-4 border border-black-500 hover:border-transparent rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="32"
                  height="32"
                  class="m-auto"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div
              class="w-full bg-transparent bg-yellow-500 font-semibold hover:text-white py-2 px-4 mt-4 border border-black-500 hover:border-transparent rounded"
            >
              <a
                :href="
                  'https://wa.me/' +
                  orsai.telefono +
                  '?text=Tuve%20un%20problema'
                "
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  class="m-auto"
                  width="32"
                  height="32"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#fff"
                    d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                  ></path>
                  <path
                    fill="#cfd8dc"
                    d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                  ></path>
                  <path
                    fill="#40c351"
                    d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                  ></path>
                  <path
                    fill="#fff"
                    fill-rule="evenodd"
                    d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <!-- Si no hay pedido -->
      <div
        class="bg-white rounded-lg shadow-lg p-4 mt-12 mx-4"
        v-if="!$route.params.id"
      >
        <p class="text-gray-800 text-center mb-4 m-auto">
          No existe pedido con esa Ordern
        </p>
        <!-- boton buscar pedido -->
        <div class="flex justify-center">
          <router-link
            :to="{ path: '/reparto/' }"
            class="bg-base-600 hover:bg-base-700 rounded-md text-white py-2 px-4"
            >Buscar Pedido</router-link
          >
        </div>
      </div>
      <!-- {{entregas}} -->
    </div>
  </div>
</template>

<script>
import moment from "moment";

import axios from "axios";
export default {
  name: "HomeView",
  layout: "reparto",
  components: {},
  data() {
    return {
      shipment: null,

      entregar: false,
      orsai: {},

      producto: {
        estado: "",
      },
      cliente: {
        dni: null,
      },
      mensaje: "",
      motoqueroMensajes: [
        [
          {
            fecha: "11-12-2023 2pm",
            nota: "Se me pincho",
          },
          {
            fecha: "11-12-2023 3pm",
            nota: "Renuncie",
          },
        ],
      ],
    };
  },
  async created() {
    // Obtener shipment con action
    this.shipment = await this.$store.dispatch(
      "getShipment",
      this.$route.params.id
    );
    // axios
    axios
      .get("https://api.orsai.org/shipments/" + this.$route.params.id)
      .then((respuestGet) => {
        this.producto.estado = respuestGet.data.estado;
        this.motoqueroMensajes = respuestGet.data.mensajes;
        this.orsai = respuestGet.data;
        console.log("this.producto.estado", this.producto.estado);
        if (this.producto.estado == "Demorado") {
          this.entregar = true;
        }
      });
  },
  methods: {
    noEntregado() {
      this.producto.estado = "Demorado";
    },
    enviarEstadoDemorado() {
      //si hay mensaje
      if (this.mensaje) {
        this.producto.estado = "Demorado";
        this.motoqueroMensajes.push({
          fecha: moment().format("MMMM Do YYYY, h:mm:ss a"), // March 27th 2023, 8:08:41 pm,
          nota: this.mensaje,
        });
        axios
          .patch("https://api.orsai.org/reparto/" + this.$route.params.id, {
            estado: this.producto.estado,
            mensajes: this.motoqueroMensajes,
          })
          .then((respuesta) => {
            console.log("respuesta", respuesta);
            this.$router.push({
              path: "/reparto/info",
              query: {
                mensaje: "El estado del paquete a sido actualizado con exito",
              },
            });
          });
      } else {
        alert("Ingrese un mensaje");
      }
    },
    enviarEstado() {
      console.log("Enviando estado al servidor");
      if (this.cliente.dni) {
        axios
          .patch("https://api.orsai.org/reparto/" + this.$route.params.id, {
            estado: "Entregado",
            mensajes: this.motoqueroMensajes,
            cliente: {
              dni: this.cliente.dni,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.orsai = res.data;
            this.$router.push({
              path: "/reparto/info",
              query: {
                mensaje: "El estado del paquete a sido actualizado con exito",
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Ingrese su DNI para poder entregar el paquete");
      }
    },
  },
};
</script>
